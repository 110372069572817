import {
  AmericanExpress,
  MastercardIcon,
  StripeIcon,
  VisaIcon,
} from '@icons';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import Link from 'next/link';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TikTok from './../../public/icons/tiktok.svg';
import TwitterIcon from '@mui/icons-material/Twitter';
import VerifiedIcon from '@mui/icons-material/Verified';
import YouTubeIcon from '@mui/icons-material/YouTube';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

export default function Footer({ hasMobileCta = null }) {
  // i18s
  const { t } = useTranslation('common');
  const { pathname, query, locale } = useRouter();

  return (
    <div className="relative text-gray-700 bg-slate-100 dark:bg-zinc-800 dark:text-white">
      <div className="px-4 pt-12 mx-auto sm:max-w-xl md:max-w-full md:px-24 lg:max-w-screen-xl lg:px-8">
        <div className="flex flex-wrap justify-between w-10/12 pt-8 pb-12 mx-auto space-y-6 lg:space-y-0">
          <div className="w-full lg:w-1/6">
            <p className="font-semibold tracking-wide">
              {t('footer.info.name')}
            </p>
            <ul className="mt-2 space-y-2 text-sm">
              <li>
                <a
                  className="underline underline-offset-4"
                  href="mailto:info@aditusculture.com"
                >
                  info@aditusculture.com
                </a>
              </li>
              <li>
                <a
                  className="underline underline-offset-4"
                  href="tel:01119272866"
                >
                  01119272866
                </a>
              </li>
              <li>Aditus S.r.l.</li>
              <li>Via Caboto, 35 10129 Torino</li>
              <li>{t('footer.info.rea')}: TO 1089615</li>
              <li>{t('footer.info.vat')} 09896500015</li>

              <li>
                <a
                  className="underline underline-offset-4"
                  href="https://mims.aditusculture.com/whistleblowing/home"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('footer.info.whistleblowing')}
                </a>
              </li>
            </ul>
          </div>
          <div className="w-full lg:w-1/6">
            <p className="font-semibold tracking-wide text-teal-accent-400">
              {t('footer.about.name')}
            </p>

            <ul className="mt-2 space-y-2 text-sm">
              <li>
                <Link href="/about" passHref>
                  <a>{t('footer.about.about_us')}</a>
                </Link>
              </li>
              <li>
                <Link href="/terms" passHref>
                  <a>{t('footer.about.terms')}</a>
                </Link>
              </li>
              <li>
                {locale === 'it' && (
                  <a
                    href="/documents/M-GDPR-30-informativa-sito-internet.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('footer.about.privacy')}
                  </a>
                )}

                {locale === 'en' && (
                  <a
                    href="/documents/M-GDPR-30-informativa-sito-internet-EN.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('footer.about.privacy')}
                  </a>
                )}
              </li>
              <li>
                <a
                  href="https://www.iubenda.com/privacy-policy/18635860/cookie-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('footer.about.cookies')}
                </a>
              </li>

              <li>
                <a
                  href="/documents/aditus_certificato-it.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('footer.about.quality_certification')}
                </a>
              </li>
              <li>
                <a
                  href="/documents/politica_della_qualita_ambiente.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('footer.about.quality_policy_environment')}
                </a>
              </li>
              <li>
                <a
                  href="/documents/policy_sicurezza.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('footer.about.security_policy')}
                </a>
              </li>
              <li>
                <a
                  href="/documents/Certificato_Ambiente–ISO14001.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('footer.about.eco_certification')}
                </a>
              </li>
              <li>
                <a
                  href="/documents/Certificato_sicurezza-informazioni–ISO27001.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('footer.about.security_informations')}
                </a>
              </li>
              <li>
                <a
                  href="/documents/codice-etico.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('footer.about.ethical_code')}
                </a>
              </li>
              <li>
                <a
                  href="/documents/politica_parita_genero.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('footer.about.parity_gender')}
                </a>
              </li>
            </ul>
          </div>

          <div className="w-full lg:w-1/6">
            <p className="font-semibold tracking-wide text-teal-accent-400">
              {t('footer.experiences.name')}
            </p>
            <ul className="mt-2 space-y-2 text-sm">
              <li>
                <Link
                  href="/esperienze/musei-parchi-archeologici"
                  passHref
                >
                  <a>{t('footer.experiences.museums')}</a>
                </Link>
              </li>
              <li>
                <Link href="/esperienze/mostre-eventi" passHref>
                  <a>{t('footer.experiences.events')}</a>
                </Link>
              </li>
              <li>
                <Link
                  href="/esperienze/tour-visite-guidate"
                  passHref
                >
                  <a>{t('footer.experiences.tours')}</a>
                </Link>
              </li>
              <li>
                <Link href="/didattica" passHref>
                  <a>{t('footer.experiences.school')}</a>
                </Link>
              </li>
              <li>
                <Link href="/esperienze/food" passHref>
                  <a>Food</a>
                </Link>
              </li>
            </ul>
          </div>

          <div className="w-full space-y-10 lg:w-1/4">
            <div>
              <p className="font-semibold tracking-wide">
                Social Media
              </p>
              <ul className="flex items-end justify-around mt-4 space-y-2 text-sm ">
                <li>
                  <a
                    href="https://www.instagram.com/aditusculture/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <InstagramIcon className="fill-gray-700 dark:fill-white" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/AditusCulture/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FacebookIcon className="fill-gray-700 dark:fill-white" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCIYt7Vdup150pVhwTnyooEA"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <YouTubeIcon className="fill-gray-700 dark:fill-white" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://it.linkedin.com/company/aditus-culture"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LinkedInIcon className="fill-gray-700 dark:fill-white" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.tiktok.com/@aditusculture?_t=8XBJ5nLRFRe&_r=1"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <TikTok className="w-5 h-5 fill-gray-700 dark:fill-white" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/aditusculture"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <TwitterIcon className="fill-gray-700 dark:fill-white" />
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <p className="flex items-center space-x-2 font-semibold tracking-wide">
                <span>{t('footer.payment.name')}</span>
                <VerifiedIcon
                  fontSize="medium"
                  className="fill-green-500"
                />
              </p>
              <ul className="flex items-end justify-start mt-2 space-y-4 text-sm ">
                <li>
                  <AmericanExpress className="h-10 w-14 fill-blue-700 dark:fill-white" />
                </li>

                <li>
                  <MastercardIcon className="h-10 w-14 fill-gray-700 dark:fill-white" />
                </li>

                <li>
                  <VisaIcon className="h-10 w-14 fill-blue-900 dark:fill-white" />
                </li>

                <li>
                  <StripeIcon className="w-16 h-10 fill-indigo-800 dark:fill-white" />
                </li>
              </ul>
            </div>

            {/* CHANGE LOCALE */}
            <div className="w-full">
              <p className="font-semibold tracking-wide">
                {t('navbar.change_locale')}
              </p>

              <ul className="flex flex-row w-full mt-2 space-x-6 text-sm">
                <li>
                  <Link
                    href={{ pathname, query }}
                    passHref
                    locale="it"
                    scroll={false}
                  >
                    <a
                      className={classNames(
                        'underline-offset-4 hover:underline',
                        {
                          'font-semibold dark:text-primary':
                            locale == 'it',
                        },
                      )}
                    >
                      IT
                    </a>
                  </Link>
                </li>
                <li>
                  <Link
                    href={{ pathname, query }}
                    passHref
                    locale="en"
                    scroll={false}
                  >
                    <a
                      className={classNames(
                        'underline-offset-4 hover:underline',
                        {
                          'font-semibold dark:text-primary':
                            locale == 'en',
                        },
                      )}
                    >
                      EN
                    </a>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          className={classNames(
            'flex flex-col justify-between sm:flex-row',
            'border-t py-6',
            { 'pb-16 pt-6 lg:!py-6': hasMobileCta },
            { 'py-6': !hasMobileCta },
          )}
        >
          <p className="w-full text-sm text-center">
            ©Copyright {new Date().getFullYear()} Aditus S.r.l. All
            rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
}
