import { Header } from '@components';
import Link from 'next/link';
import { Section } from '@components';
import { getCleanedPaths } from '@utils';
import {
  useGetTopSalesQuery,
  useGetStrapiSitesQuery,
} from '@services';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import calendar from 'dayjs/plugin/calendar';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import relativeTime from 'dayjs/plugin/relativeTime';
import classNames from 'classnames';
import capitalize from 'lodash/capitalize';
import { useMediaQueries } from '@hooks';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import MovingIcon from '@mui/icons-material/Moving';

dayjs.extend(relativeTime);
dayjs.extend(isSameOrBefore);
dayjs.extend(calendar);
dayjs.extend(duration);

export default function BestSellers({
  title = 'Top 5',
  label = 'Most Appreciated',
  description = '',
}) {
  const { isMobile } = useMediaQueries();
  const { locale } = useRouter();
  const { t } = useTranslation('common');
  const today = dayjs();
  const limit = dayjs().hour(19).minute(0).second(0);

  const dates = [
    {
      label: t('generic.labels.today'),
      date: new Date(),
      threshold: today.isSameOrBefore(limit),
    },
    {
      label: dayjs(today).add(1, 'day').format('dddd'),
      date: dayjs(today).add(1, 'day'),
      threshold: true,
    },
    {
      label: dayjs(today).add(2, 'day').format('dddd'),
      date: dayjs(today).add(2, 'day'),
      threshold: true,
    },
  ];

  const {
    data: toSales = [],
    isSuccess: isTopSalesSuccess,
    isLoading: isTopSalesLoading,
  } = useGetTopSalesQuery();

  const {
    data: sites,
    isLoading: isSitesLoading,
    isSuccess: isSitesSuccess,
  } = useGetStrapiSitesQuery({ locale });

  return (
    <Section
      id="bestSellers"
      isLoading={isSitesLoading && isTopSalesLoading}
    >
      <Header
        title={title}
        label={label}
        description={description}
      />

      {isTopSalesSuccess &&
        isSitesSuccess &&
        toSales?.length > 0 &&
        sites?.data?.length > 0 && (
          <div
            className={classNames(
              'flex flex-col',
              'w-full mx-auto md:w-2/3',
              'space-y-8 3xl:space-y-12',
              'select-none pt-8'
            )}
          >
            {toSales.map((p, i) => {
              const { cityPath, prodPath } = getCleanedPaths({
                city: p?.city,
                slug: p?.canonical_name,
                typeSegment: '/musei-parchi-archeologici/',
              });

              const { attributes } = sites.data.filter(
                ({ attributes }) =>
                  attributes?.slug === p?.canonical_name,
              )[0];

              
              const { name, intro_subtitle } = attributes;
              const city = attributes?.city?.data?.attributes?.name;

              return (
                <div
                  key={i}
                  className={classNames(
                    'flex items-center justify-center',
                    'w-full mx-auto space-x-2',
                  )}
                >
                  <div className="flex flex-col dark:text-white">
                    <Link passHref href={cityPath}>
                      <a
                        className={classNames(
                          'w-full mb-2',
                          'text-sm text-center uppercase',
                          'hover:underline underline-offset-2',
                          'dark:text-primary-300',
                        )}
                      >
                        {city}
                      </a>
                    </Link>
                    <Link passHref href={prodPath}>
                      <a
                        className={classNames(
                          'relative z-10 mb-2',
                          'space-x-3',
                          'text-center',
                          'font-semibold font-title',
                          'text-lg lg:text-2xl xl:text-3xl',
                          'underline-offset-4 hover:underline',
                          'flex items-center justify-center'
                        )}
                      >
                        <span className="hidden w-5 text-sm text-secondary lg:inline-flex">
                          {i + 1}
                        </span>
                        {name}
                      </a>
                    </Link>

                    {p?.sales && p.sales > 50 && (
                      <span className="text-sm italic text-center md:text-lg lg:ml-5">
                        + {p.sales} {t('sections.top-5.info')}
                        {i < 3 && (
                          <MovingIcon fontSize='small' className="mx-2 fill-green-500" />
                        )}
                      </span>
                    )}

                    {i < 3 && !isMobile && (
                      <Stack
                        spacing={0.5}
                        direction={isMobile ? 'column' : 'row'}
                        className="items-center justify-center mt-4"
                        divider={
                          <Divider
                            flexItem
                            orientation={
                              isMobile ? 'horizontal' : 'vertical'
                            }
                          />
                        }
                      >
                        {dates?.map(
                          ({ label, date, threshold }, y) => {
                            if (threshold) {
                              return (
                                <Link
                                  key={y}
                                  passHref
                                  href={{
                                    pathname: prodPath,
                                    query: {
                                      date: dayjs(date).format(),
                                    },
                                  }}
                                >
                                  <a
                                    className={classNames(
                                      'text-sm italic font-light text-center',
                                      'hover:underline underline-offset-4',
                                      'md:max-w-full',
                                      'opacity-70 hover:opacity-100',
                                      'transition-opacity ease-in-out duration-300',
                                    )}
                                  >
                                    {t('generic.labels.buy')}
                                    <span className="px-1">
                                      {capitalize(label)}
                                    </span>
                                  </a>
                                </Link>
                              );
                            }
                          },
                        )}
                      </Stack>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
    </Section>
  );
}
