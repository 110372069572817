import '@styles/global.scss';
import '@styles/main.scss';
import '@splidejs/splide/dist/css/splide.min.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'simplebar-react/dist/simplebar.min.css';
import 'dayjs/locale/it';

import {
  AuthProvider,
  CustomThemeProvider,
  GdprProvider,
} from '@containers';
import { PixelEvent, PixelPageView } from '@utils';
import { getCookie, hasCookie } from 'cookies-next';
import { persistor, store } from '@store';

import { FullScreenLoader } from '@components';
import { PersistGate } from 'redux-persist/integration/react';
import ProgressBar from '@badrap/bar-of-progress';
import { Provider } from 'react-redux';
import Router from 'next/router';
import Script from 'next/script';
import { appWithTranslation } from 'next-i18next';
import dayjs from 'dayjs';
import { useEffect } from 'react';

const PB = new ProgressBar({
  size: 3,
  delay: 100,
  color: '#faaf40',
  className: 'bar-of-progress',
});

function App({ Component, pageProps, router }) {
  const consent = getCookie('localConsent');
  console.log({
    hasCookie: hasCookie('localConsent'),
  });

  useEffect(() => {
    if (consent) PixelPageView();

    Router.events.on('routeChangeStart', PB.start);
    Router.events.on('routeChangeError', PB.finish);
    Router.events.on('routeChangeComplete', () => {
      if (consent) PixelPageView();
      PB.finish();
    });

    return () => {
      Router.events.off('routeChangeStart', PB.start);
      Router.events.off('routeChangeError', PB.finish);
      Router.events.off('routeChangeComplete', () => {
        if (consent) PixelPageView();
        PB.finish();
      });
    };
  }, [Router.events]);

  useEffect(() => {
    console.log('router.locale', router.locale);

    dayjs.locale(router.locale);
  }, [router.locale]);

  return (
    <>
      <Script
        id="gtag"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}


            gtag('consent', 'default', {
              'ad_storage': 'denied',
              'analytics_storage': 'denied'
            });

            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                      })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER}');`,
        }}
      />

      {hasCookie('localConsent') && consent && (
        <>
          <Script
            id="consupd"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
            gtag('consent', 'update', {
              'ad_storage': 'granted',
              'analytics_storage': 'granted'
            });
          `,
            }}
          />

          <Script
            id="fb-pixel"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '539178213280122');`,
            }}
          />
        </>
      )}

      <Provider store={store}>
        <PersistGate
          persistor={persistor}
          loading={<FullScreenLoader />}
        >
          <CustomThemeProvider>
            <GdprProvider>
              <AuthProvider>
                <Component {...pageProps} key={router.asPath} />
              </AuthProvider>
            </GdprProvider>
          </CustomThemeProvider>
        </PersistGate>
      </Provider>
    </>
  );
}

export default appWithTranslation(App);
